import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3572675178/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Programs`}</h1>
    <h2>{`What is a Program`}</h2>
    <p>{`A program represents a distinct group of linked cards. The program is the parent object of your card-linked structure. Other objects such as cards and transactions will link to a program. Programs are unique and independent of each other, allowing you to have several independent programs with different uses inside your account.`}</p>
    <p>{`To be able to use the Transaction Stream API, you need to create a program first.`}</p>
    <h5>{`Hierarchy diagram of the Fidel API object structure.`}</h5>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/program-objects-hierarchy.png%22",
        "alt": null
      }}></img></p>
    <h2>{`Create a Program`}</h2>
    <p>{`You can create a program through the Fidel API dashboard or via API. In `}<a parentName="p" {...{
        "href": "/stream/test-and-live-modes/#test-mode"
      }}>{`test mode`}</a>{`, you can create programs that use either Select Transactions API or Transaction Stream API, and in `}<a parentName="p" {...{
        "href": "/stream/test-and-live-modes/#live-mode"
      }}>{`live mode`}</a>{`, you can create programs that use the API type that your account is approved for. Once a program is created, a program identifier will be generated and is used as a data property when using the SDKs and APIs to link cards to this program. Cards are linked to programs and consequently are able to track all purchases in the program.`}</p>
    <p>{`To create a new program on the Fidel API dashboard, go to the `}<strong parentName="p">{`Programs`}</strong>{` page and click the `}<strong parentName="p">{`New program`}</strong>{` button. If in test mode, choose the API type and enter a name for the new program.`}</p>
    <h2>{`For Developers`}</h2>
    <p>{`You can create programs using the `}<a parentName="p" {...{
        "href": "https://transaction-stream.fidel.uk/reference/create-program"
      }}>{`API`}</a>{`. Using curl, we can add 'Program X' as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST https://api.fidel.uk/v1/programs \\
  -H 'content-type: application/json' \\
  -H 'fidel-key: <secret key>' \\
  -d '{
    "name": "Program X",
    "metadata": {
      "customKey1": "customValue1",
      "customKey2": "customValue2"
    },
    "type" : "transaction-stream"
  }'
`}</code></pre>
    <h2>{`Learn More`}</h2>
    <ul>
      <li parentName="ul">{`After setting up your program, you can go ahead and `}<a parentName="li" {...{
          "href": "/stream/cards"
        }}>{`create test cards`}</a>{` and `}<a parentName="li" {...{
          "href": "/stream/webhooks"
        }}>{`webhooks`}</a>{`, and `}<a parentName="li" {...{
          "href": "/stream/transactions"
        }}>{`create test transactions`}</a>{` to see how Fidel API works.`}</li>
      <li parentName="ul">{`Walk through our `}<a parentName="li" {...{
          "href": "/stream/tutorials/card-linking"
        }}>{`tutorial`}</a>{` to start using the APIs and SDKs.`}</li>
      <li parentName="ul">{`Learn about the `}<a parentName="li" {...{
          "href": "/stream/sdks/web/v3"
        }}>{`SDKs`}</a>{`.`}</li>
      <li parentName="ul">{`Check out the `}<a parentName="li" {...{
          "href": "https://transaction-stream.fidel.uk/reference/introduction-1"
        }}>{`API reference docs`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      